@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Outfit-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Outfit-Regular.ttf') format('truetype');
    }
}

.mdxeditor  {
    h1 {
        font-size: 2em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    h2 {
        font-size: 1.5em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    h3 {
        font-size: 1em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;

    }

    p {
        font-size: 1em;
        margin-bottom: 0.5em;
    }
    ul {
        list-style-type: disc;
        margin: 0.5em;
        padding: 0.5em;
    }
    ol {
        list-style-type: decimal;
        margin: 0.5em;
        padding: 0.5em;
    }
    a {
        color: #3B85F6;
    }
    blockquote {
        background: #eee;
        padding: 0.5em;
        margin: 0.5em;
    }
}